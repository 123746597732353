import AppModal from '@components/AppModal';
import { Col, Input, Row } from 'reactstrap';
import clsx from 'clsx';
import styles from './index.module.scss';
import nameof from 'ts-nameof.macro';
import { AddressDatumDto, TokenType } from '@models/awbs/awbsModels';
import i18next, { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@root/store';
import {
    changeParsedTokenType,
    clearParsedTokens,
    createAddressDatum,
    createOrUpdateAddress,
    getParticipantContactInfoById,
    parseAddress,
    setParsedData,
    updateAddressDatum
} from '@store/addressDataStore';
import { useDispatch } from 'react-redux';
import { AddressParsedToken } from '@scenes/customerApplication/awb/components/AddressParsedToken';
import LocationsService from '@services/LocationsService';
import { LocationDto, LocationType } from '@models/locations';
import { ValidationError } from 'yup';
import { useAddressValidation } from '@scenes/customerApplication/awb/components/validations/addressValidation';
import { AxiosResponse } from 'axios';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { useDebouncedCallback } from 'use-debounce';
import { OptionTypeBase } from 'react-select';
import { CountrySelect } from '@scenes/customerApplication/awb/components/CountrySelect';

interface Props {
    id?: string;
    title: string;
    scannedData?: string;
    showAccountNumber?: boolean;
    allowEmptyAccountNumberTitle?: string;
    onClose: (addressDatum: AddressDatumDto) => void;
}

interface TokenInfo {
    value: TokenType;
    labelKey: string;
}

enum EditState {
    None,
    Confirming,
    Confirmed,
    Closed
}

enum CityEditState {
    None,
    NeedToCreate,
    Creating,
    Saving
}

export const AddressDatumModal = ({ id, scannedData, showAccountNumber, title, allowEmptyAccountNumberTitle, onClose}: Props) => {
    const {addressDatum, parsedTokens, isParsing} = useAppSelector(x => x.addressData);
    const [editedAddress, setEditedAddress] = useState<AddressDatumDto>(null);
    const [editState, setEditState] = useState(EditState.None);
    const [currentTokenIndex, setCurrentTokenIndex] = useState<number>(- 1);
    const [city, setCity] = useState<LocationDto>(null);
    const [validationErrors, setValidationErrors] = useState<ValidationError[]>([]);
    const [message, setMessage] = useState('');
    const [fieldState, setFieldState] = useState<Record<string, EditState>>({});
    const [allowEmptyInn, setAllowEmptyInn] = useState(false);
    const [rawData, setRawData] = useState(scannedData);
    const [cityName, setCityName] = useState('');
    const [cityEditState, setCityEditState] = useState(CityEditState.None);
    const [cities, setCities] = useState<LocationDto[]>([]);
    const [selectedCity, setSelectedCity] = useState<LocationDto>(null);
    const [addressAsString, setAddressAsString] = useState('');

    const dispatch = useDispatch();

    const schema = useAddressValidation(showAccountNumber);
    const validateAsync = async (addressDatum: AddressDatumDto) => {
        try {
            await schema.validate(addressDatum, {abortEarly: false});
            setValidationErrors([]);
        } catch (e) {
            const error = e as ValidationError;
            setValidationErrors(error.inner);
        }
    };

    const fetchCity = async (cityId: string) => {
        if (cityId == city?.id) {
            return city;
        }

        let cityItem: LocationDto = null;

        if (cityId != null) {
            const data = await new LocationsService().getLocationById(cityId);
            if (!data.data.isError) {
                cityItem = data.data;
            }
        }

        return cityItem;
    };

    const initCity = async () => {
        const city = await fetchCity(editedAddress.cityId);
        setCity(city);
        if (city !== null || editedAddress.cityName != null) {
            setSelectedCity(city || {id: null, name: editedAddress.cityName});
        }

        await validateAsync(editedAddress);
    }

    const initializeEmptyFields = () => {
        const newValues = {};
        const newFieldState: Record<string, EditState> = {};

        for (const key of Object.keys(addressDatum)) {
            const textLength = editedAddress[key]?.length || 0;
            if (textLength == 0 && addressDatum[key] != null) {
                newValues[key] = addressDatum[key];
                newFieldState[key] = EditState.Confirmed;
            }
        }

        setEditedAddress({...editedAddress, ...newValues});
        setFieldState({...fieldState, ...newFieldState});
    }

    const processAddressDatum = () => {
        switch (editState) {
            case EditState.None:
                // setAllowEmptyInn(addressDatum != null && (addressDatum.accountNumber || '') == '');
                setEditedAddress(addressDatum);
                break;

            case EditState.Confirming:
                initializeEmptyFields();
                break;

            case EditState.Confirmed:
                dispatch(clearParsedTokens());
                onClose(addressDatum);
                break;
        }
    };

    useEffect(() => {
        let current = true;
        if (current) {
            processAddressDatum();
        }

        return () => {
            current = false;
        }
    }, [addressDatum]);

    useEffect(() => {
        setCity(null);
        setEditedAddress(null);
        dispatch(clearParsedTokens());
        dispatch(id != null ? getParticipantContactInfoById(id) : createAddressDatum());
    }, [id]);

    useEffect(() => {
        if (editedAddress == null) {
            setCity(null);
            return;
        }

        setAllowEmptyInn(editedAddress.isRequiredAccountNumber === false);
        initCity()
            .then(() => {
                const fields: string[] = [];
                if (editedAddress.isRequiredAccountNumber) {
                    fields.push(editedAddress.accountNumber);
                }
                fields.push(editedAddress.name || '');

                const cityName = city?.name || editedAddress.cityName || '';
                if (cityName.length > 0) {
                    fields.push(cityName);
                }

                fields.push(editedAddress.stateProvince || '');
                fields.push(editedAddress.postCode || '');
                fields.push(editedAddress.address || '');
                fields.push(editedAddress.contact || '');
                setAddressAsString(fields.filter(v => v.length > 0).join(', '));
            });
    }, [editedAddress]);

    useEffect(() => {
        if (parsedTokens.length > 0) {
            setMessage('');
        }
    }, [parsedTokens]);

    useEffect(() => {
        validateAsync(editedAddress);
    }, [allowEmptyInn]);

    const tokenTypes: TokenInfo[] = [
        {
            value: TokenType.Unknown,
            labelKey: 'awb.unknown'
        },
        {
            value: TokenType.Address,
            labelKey: 'awb.address'
        },
        {
            value: TokenType.AccountNumber,
            labelKey: 'awb.accountNumber'
        },
        {
            value: TokenType.Name,
            labelKey: 'awb.name'
        },
        {
            value: TokenType.PostalCode,
            labelKey: 'awb.postCode'
        },
        {
            value: TokenType.Place,
            labelKey: 'awb.place'
        },
        {
            value: TokenType.Phone,
            labelKey: 'awb.contact'
        }
    ]

    const renderTokens = () => {
        if (currentTokenIndex >= 0) {
            return <div onClick={e => {
                e.preventDefault();
                setCurrentTokenIndex(- 1);
            }}>
                <AddressParsedToken token={parsedTokens[currentTokenIndex]} onClick={() => setCurrentTokenIndex(- 1)}/>
                <div className={styles.popup}>
                    {
                        tokenTypes
                            .filter(info => info.value != TokenType.AccountNumber || showAccountNumber)
                            .map((info, idx) =>
                                <label key={idx} onClick={e => e.stopPropagation()}>
                                    <input type={"radio"}
                                           name={'token-type'}
                                           value={info.value}
                                           defaultChecked={info.value === parsedTokens[currentTokenIndex].tokenType}
                                           onChange={async () => {
                                               let id = null;

                                               if (info.value == TokenType.Place) {
                                                   const {data: search} = await new LocationsService().getLocations(info.labelKey, LocationType.City);
                                                   if (search.length > 0) {
                                                       id = search[0].id;
                                                   }
                                               }

                                               dispatch(changeParsedTokenType({
                                                   tokenIndex: currentTokenIndex,
                                                   tokenType: info.value,
                                                   id: id
                                               }));

                                               setFieldState({});
                                               setEditState(EditState.None);
                                               setCurrentTokenIndex(- 1);
                                           }}
                                    />
                                    {t(info.labelKey)}
                                </label>)
                    }
                </div>
            </div>
        }

        return parsedTokens?.map((token, idx) => <AddressParsedToken onClick={() => setCurrentTokenIndex(idx)} key={idx}
                                                                     token={token}/>);
    }

    const parse = () => {
        dispatch(parseAddress(rawData));
    };

    const renderValidationError = (fieldName: string) => {
        if (validationErrors == null) {
            return null;
        }

        const [validationError] = validationErrors.filter(value => value.path == fieldName);
        if (validationError == null) {
            return null;
        }

        return <div><span className="validationMessage">{validationError.message}</span></div>;
    }

    const renderConfirmBlock = (fieldName: string) => {
        if (addressDatum == null || fieldState[fieldName] == EditState.Confirmed) {
            return null;
        }

        const newValue = addressDatum[fieldName];

        if (newValue == null || editState != EditState.Confirming || editedAddress[fieldName] == newValue) {
            return null;
        }

        const oldValue = {[fieldName]: editedAddress[fieldName]};

        return <>
            <button
                className={'btn btn-primary'}
                onMouseOver={() => setMessage(t('awb.confirmReplace', {text: newValue}))}
                onMouseOut={() => setMessage('')}
                onClick={e => {
                    e.preventDefault();
                    setFieldState({...fieldState, [fieldName]: EditState.Confirmed});
                    setMessage('');
                    setEditedAddress({...editedAddress, [fieldName]: newValue});
                }}>
                {t('awb.replaceField')}
            </button>
            <button className={'btn btn-secondary'} onClick={e => {
                e.preventDefault();
                setFieldState({...fieldState, [fieldName]: EditState.Confirmed});
                setMessage('');
                updateAddressDatum({...addressDatum, ...oldValue});
            }}>
                {t('awb.skipField')}
            </button>
        </>;
    };

    const CreateCityBlock = () => {
        if (cityEditState == CityEditState.None) {
            return null;
        }

        const onCreateBtnClick = () => {
            switch (cityEditState) {
                case CityEditState.NeedToCreate:
                    setCityEditState(CityEditState.Creating);
                    setEditedAddress({
                        ...editedAddress,
                        [nameof<AddressDatumDto>(m => m.cityId)]: null,
                    });
                    break;

                case CityEditState.Creating:
                    setEditedAddress({
                        ...editedAddress,
                        [nameof<AddressDatumDto>(m => m.cityName)]: cityName,
                    });
                    setCityName('');
                    break;
            }
        };

        return <>
            <button
                className={'btn btn-primary'}
                disabled={cityEditState == CityEditState.Saving || editedAddress.cityName == ''}
                onClick={e => {
                    e.preventDefault();
                    onCreateBtnClick();
                }}>
                {t('awb.create')}
            </button>
            <button
                disabled={cityEditState == CityEditState.Saving}
                className={'btn btn-secondary'}
                onClick={e => {
                    e.preventDefault();
                    setCityEditState(CityEditState.None);
                }}>
                {t('awb.cancel')}
            </button>
        </>;
    };

    const fetchCitiesDebounced = useDebouncedCallback((term: string, resolve: (options: LocationDto[]) => void) => {
        new LocationsService()
            .getLocations(term, LocationType.City)
            .then((data: AxiosResponse<LocationDto[]>) => {
                let locations = editedAddress.cityId == null
                    ? data.data
                    : data.data.filter(location => location.id == editedAddress.cityId);

                if (editedAddress.countryId != null) {
                    locations = locations.filter(l => l.parentId == editedAddress.countryId)
                }

                if (locations.length == 0) {
                    setCityName(term);
                }

                setCities(locations);
                resolve(locations);
            });
    }, 400);

    const fetchCities = (term: string) =>
        new Promise<LocationDto[]>(resolve => {
            if (term.length < 3) {
                return;
            }

            fetchCitiesDebounced(term, resolve);
        });

    return <AppModal style={{maxWidth: "800px"}} isOpen onClickCloseButton={() => onClose(null)} body={
        <div data-cy={"addressModal"}>
            <h4>{title}</h4>
            {showAccountNumber &&
                <Row className={clsx('mt-4', styles.AccountNumber)}>
                    <Col>
                        <div className={styles.flexContainer}>
                            <Input
                                disabled={allowEmptyInn}
                                className={styles.flexControl}
                                name={nameof<AddressDatumDto>(m => m.accountNumber)}
                                placeholder={t(allowEmptyAccountNumberTitle || (allowEmptyInn ? 'awb.allowEmptyAccountNumber' : 'awb.accountNumber'))}
                                value={allowEmptyInn ? '' : editedAddress?.accountNumber ?? ''}
                                onChange={e => setEditedAddress({
                                    ...editedAddress,
                                    [nameof<AddressDatumDto>(m => m.accountNumber)]: e.target.value
                                })}/>
                            {renderConfirmBlock(nameof<AddressDatumDto>(m => m.accountNumber))}
                            <div className={clsx("form-check", styles.allowEmptyInn)}>
                                <input className="form-check-input" type="checkbox" id='no-account-number'
                                       checked={allowEmptyInn} onChange={(e) => {
                                    setAllowEmptyInn(e.currentTarget.checked);
                                    setEditedAddress({
                                        ...editedAddress,
                                        [nameof<AddressDatumDto>(m => m.isRequiredAccountNumber)]: !e.currentTarget.checked
                                    })
                                }}/>
                                <label className={'control-label'} htmlFor='no-account-number'>
                                    {allowEmptyAccountNumberTitle || t('awb.allowEmptyAccountNumber')}
                                </label>
                            </div>
                        </div>
                        {renderValidationError(nameof<AddressDatumDto>(m => m.accountNumber))}
                    </Col>
                </Row>}
            <Row className={clsx('mt-4', styles.Name)}>
                <Col>
                    <div className={styles.flexContainer}>
                        <Input
                            className={styles.flexControl}
                            name={nameof<AddressDatumDto>(m => m.name)}
                            placeholder={t('awb.name')}
                            value={editedAddress?.name ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.name)]: e.target.value
                            })}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.name))}
                    </div>
                    {renderValidationError(nameof<AddressDatumDto>(m => m.name))}
                </Col>
            </Row>
            <Row className={clsx('mt-4', styles.Place)}>
                <Col>
                    <div className={styles.flexContainer}>
                        <div className={styles.country}>
                            <CountrySelect countryId={editedAddress?.countryId} onChange={value => {
                                setSelectedCity(null);
                                setEditedAddress({
                                    ...editedAddress,
                                    [nameof<AddressDatumDto>(m => m.countryId)]: value?.id,
                                    [nameof<AddressDatumDto>(m => m.cityId)]: null,
                                });
                            }}/>
                        </div>
                        <div style={{flexGrow: 1}}>
                            <AsyncCreatableSelect<LocationDto, false>
                                placeholder={t('awb.place')}
                                cacheOptions
                                loadOptions={(inputValue, callback) => fetchCities(inputValue)}
                                formatOptionLabel={(option, labelMeta) => (option as OptionTypeBase).label || option.name}
                                isClearable
                                value={selectedCity}
                                onChange={(value, action) => {
                                    setSelectedCity(value);
                                    setEditedAddress({
                                        ...editedAddress,
                                        [nameof<AddressDatumDto>(m => m.cityId)]: value?.id,
                                    })
                                }}
                                loadingMessage={({inputValue}) => inputValue.length < 3 ? t('options.specifyAtLeast3Chars') : t('options.loadingOptionsTemplate', {itemType: t('awb.itemType')})}
                                formatCreateLabel={inputValue => i18next.t('awb.setUnknownCity', { city: inputValue })}
                                onCreateOption={inputValue => {
                                    const value: LocationDto = {id: null, name: inputValue, type: LocationType.City};
                                    setCities([...cities, value]);
                                    setSelectedCity(value);
                                    setEditedAddress({
                                        ...editedAddress,
                                        [nameof<AddressDatumDto>(m => m.cityName)]: inputValue,
                                        [nameof<AddressDatumDto>(m => m.cityId)]: null,
                                    });
                                }}
                            />
                        </div>
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.cityId))}
                        <CreateCityBlock/>
                    </div>
                    {renderValidationError(nameof<AddressDatumDto>(m => m.countryId))}
                    {renderValidationError(nameof<AddressDatumDto>(m => m.cityId))}
                </Col>
            </Row>
            <Row className={clsx('mt-4', styles.State)}>
                <Col>
                    <div className={styles.flexContainer}>
                        <Input
                            name={nameof<AddressDatumDto>(m => m.stateProvince)}
                            placeholder={t('awb.stateProvince')}
                            value={editedAddress?.stateProvince ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.stateProvince)]: e.target.value
                            })}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.stateProvince))}
                    </div>
                </Col>
            </Row>
            <Row className={clsx('mt-4', styles.PostalCode)}>
                <Col>
                    <div className={styles.flexContainer}>
                        <Input
                            name={nameof<AddressDatumDto>(m => m.postCode)}
                            placeholder={t('awb.postCode')}
                            value={editedAddress?.postCode ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.postCode)]: e.target.value
                            })}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.postCode))}
                    </div>
                    {renderValidationError(nameof<AddressDatumDto>(m => m.postCode))}
                </Col>
            </Row>
            <Row className={clsx('mt-4', styles.Address)}>
                <Col>
                    <div className={styles.flexContainer}>
                        <Input
                            className={styles.flexControl}
                            name={nameof<AddressDatumDto>(m => m.address)}
                            placeholder={t('awb.address')}
                            value={editedAddress?.address ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.address)]: e.target.value
                            })}
                        />
                    </div>
                    {renderValidationError(nameof<AddressDatumDto>(m => m.address))}
                </Col>
            </Row>
            <Row className={clsx('mt-4', styles.Phone)}>
                <Col>
                    <div className={styles.flexContainer}>
                        <Input
                            name={nameof<AddressDatumDto>(m => m.contact)}
                            placeholder={t('awb.contact')}
                            value={editedAddress?.contact ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.contact)]: e.target.value
                            })}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.contact))}
                    </div>
                </Col>
            </Row>
            <Row className={clsx('mt-4', styles.Phone)}>
                <Col>
                    <div className={styles.flexContainer}>
                        <Input
                            name={nameof<AddressDatumDto>(m => m.extraContact)}
                            placeholder={t('awb.contact')}
                            value={editedAddress?.extraContact ?? ''}
                            onChange={e => setEditedAddress({
                                ...editedAddress,
                                [nameof<AddressDatumDto>(m => m.extraContact)]: e.target.value
                            })}
                        />
                        {renderConfirmBlock(nameof<AddressDatumDto>(m => m.extraContact))}
                    </div>
                </Col>
            </Row>
            <Row className={'mt-4'}>
                <Col>
                    <div className={clsx(styles.dummyTextarea)}>
                        {!scannedData || parsedTokens?.length == 0
                            ? <textarea
                                className={styles.rawData}
                                onChange={e => setRawData(e.currentTarget.value)}
                                value={rawData || addressAsString}/>
                            : renderTokens()}
                    </div>
                </Col>
            </Row>

            <Row className={'mt-4'}>
                <Col>
                    {message}
                </Col>
            </Row>
        </div>
    }
                     footer={
                         <>
                             <button
                                 disabled={validationErrors != null && validationErrors.length > 0 || cityEditState > CityEditState.None}
                                 className={'btn btn-primary d-block float-left px-4'}
                                 onClick={(e) => {
                                     e.preventDefault();
                                     setEditState(EditState.Confirmed);

                                     const newValue = {
                                         ...editedAddress,
                                         accountNumber: allowEmptyInn ? '' : editedAddress.accountNumber
                                     };
                                     dispatch(createOrUpdateAddress(newValue));
                                 }}
                             >
                                 {t('save')}
                             </button>
                             <button
                                 className={'btn btn-primary d-block float-left px-4'}
                                 onClick={(e) => {
                                     e.preventDefault();
                                     setFieldState({});
                                     setEditState(EditState.Closed);
                                     onClose(null);
                                 }}>
                                 {t('cancel')}
                             </button>
                             {rawData && editState == EditState.None
                                 && parsedTokens?.length == 0
                                 &&
                                 <button
                                     className={'btn btn-primary d-block float-left px-4'}
                                     disabled={isParsing}
                                     onClick={() => {
                                         setMessage(t('awb.parsing'));
                                         parse();
                                     }}>{t('request.parse')}
                                 </button>}
                             {parsedTokens?.filter(t => t.tokenType != TokenType.Unknown).length > 0
                                 && editState == EditState.None
                                 &&
                                 <button
                                     className={'btn btn-primary d-block float-left px-4'}
                                     onClick={() => {
                                         setCityName('');
                                         setEditState(EditState.Confirming);
                                         dispatch(setParsedData(rawData));
                                     }}>
                                     {t('awb.apply')}
                                 </button>}
                         </>
                     }/>
}
